import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

// Elements
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import Slider from "../elements/slider"
import Subscribe from "../elements/subscribe"
import Card from "../elements/card"
import Heading from "../elements/heading"

//images
import img1 from "../images/event1.jpeg"
import img2 from "../images/event2.jpeg"
import img3 from "../images/event3.jpeg"
import img4 from "../images/event33.jpeg"
import img5 from "../images/event4.jpeg"
import img6 from "../images/event5.jpeg"
// import img7 from "../images/event6.jpeg"
import img8 from "../images/event7.jpeg"
import img9 from "../images/event8.jpeg"
import img10 from "../images/event88.jpeg"
import img11 from "../images/event9.jpeg"
import img12 from "../images/event10.jpeg"
import img13 from "../images/event100.jpeg"
import img14 from "../images/event1010.jpeg"
import img15 from "../images/event11.jpeg"
import img16 from "../images/event12.jpeg"

//styles
import * as styles from "../styles/pages/event.module.scss"

const EventsPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEventPost(sort: { fields: updatedAt, order: DESC }) {
        edges {
          node {
            title
            slug
            subtitle
            description
            createdAt(formatString: "MMMM Do, YYYY")
            image {
              fluid(maxWidth: 220, maxHeight: 300) {
                src
              }
            }
          }
        }
      }
      file(relativePath: { eq: "sliderImage5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const posts = data.allContentfulEventPost.edges
  const image = data.file.childImageSharp.fluid
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "events" })}
        keywords={[
          `${intl.formatMessage({ id: "title" })}`,
          `${intl.formatMessage({ id: "seo" })}`,
        ]}
        description="At New Macau Casino we feature exciting promotions and events every single week. New Macau Casino is your destination for good fortune!"
      />
      <Slider
        sliderImage={image}
        sliderTitle={intl.formatMessage({ id: "events_title" })}
        sliderSubtitle={intl.formatMessage({ id: "events_subtitle" })}
        objectPosition="5% 50%"
      />
      <Subscribe />

      <Heading
        title={intl.formatMessage({ id: "events_heading" })}
        description={intl.formatMessage({ id: "events_subheading" })}
        styles={{ marginTop: "20px" }}
      />

      {/* <div className="events">
        {posts.map(post => {
          return (
            <Card
              key={post.node.title}
              image={post.node.image.fluid}
              title={post.node.title}
              subtitle={post.node.subtitle}
              description={post.node.description}
              buttonLink={`/events/${post.node.slug}`}
            />
          )
        })}
      </div> */}

      <Container>
        <Row className={styles.gridContainer}>
          <Col sm={4} className={styles.gridItem}>
            <img src={img1} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img2} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img3} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img4} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img5} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img6} alt="" className={styles.images} />
          </Col>
          {/* <Col>
            <img src={img7} alt="" />
          </Col> */}
          <Col sm={4} className={styles.gridItem}>
            <img src={img8} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img9} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img10} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img11} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img12} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img13} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img14} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img15} alt="" className={styles.images} />
          </Col>
          <Col sm={4} className={styles.gridItem}>
            <img src={img16} alt="" className={styles.images} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default injectIntl(EventsPage)
